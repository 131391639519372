a:visited {
    text-decoration: none;
    color: black;
}

.blank-icon {
  color: #d1d1d1;
}

.blank-text {
  padding-left: 10px;
}

.bullet {
    margin-left: 10px;
    margin-top: 7px;
    font-family: "IBM Plex Sans", sans-serif;
}

.glossary {
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.glossary-header{
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  text-align: center;
}

.glossary-h3 {
  line-height: '1';
}

.link {
  text-decoration: none;
  color: white;
}

.link-text {
text-decoration: none;
color: black;
}

.program-h3 {
  margin-block-start: 18px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
}


.program-header {
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  text-align: center;
}

.program-practice {
  font-size: 1em;
  padding-left: 10px;
  padding-top: 22px;
  padding-bottom: 22px;
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  background-color: #dee8fa;
}

.program-big-sub-header {
  margin-block-start: 18px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.9em;
}

.program-sub-header {
  margin-block-start: 18px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.program-sub-header-unbold {
  margin-block-start: 18px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.program-text {
  font-size: 1em;
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
}

.small-text {
  font-size: .85em;
  margin-block-start: 5px;
}

.welcome-box-header {
  color: #00448D;
  font-weight: bold;
  margin-block-start: 5px;
  margin-block-end: 5px;
}