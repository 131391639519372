.blog-header {
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    text-align: center;
    font-size: 37px
}

.blog-practice {
    font-size: 1em;
    padding-left: 10px;
    padding-top: 22px;
    padding-bottom: 22px;
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    background-color: #dee8fa;
}

.blog-sub-header {
    font-size: 1.7em; 
    margin-block-start: 10px;
    margin-block-end: 0px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.blog-sub-header-unbold {
    margin-block-start: 18px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.blog-text {
    font-size: 1.1em;
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    line-height: 1.5;
    color: black
}

.blog-quote {
    font-size: 1.1em;
    margin-left: 18px;
    font-style: italic;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-family: "IBM Plex Sans", sans-serif;
    line-height: 1.2;
    color: black
}

.blog-text-single {
    font-size: 1em;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-family: "IBM Plex Sans", sans-serif;
}

.blog-title {
    color: white;
    align-items: left;
    margin-right: 40px;
    font-size: 16px;
}

.bullet {
    margin-left: 10px;
    margin-bottom: 15px;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.1em;
    line-height: 1.5;
}

.list-format {
    padding: '0px';
    margin: '0px'
}