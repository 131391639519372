.about-text {
    font-size: 1.1em;
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
}

.blog-card-header {
    font-size: 1.25em;
    margin-block-start: 3px;
    margin-block-end: 3px;
    padding: 8px;
    line-height: 1.2;
    font-family: "IBM Plex Sans", sans-serif;
}

.blog-card-text {
    font-size: .8em;
    padding-top: 0px;
    margin-top: 0px;
    line-height: 1.3;
    font-family: "IBM Plex Sans", sans-serif;
}

.button-text:hover {
    font-size: 12px;
    margin-block-start: 3px;
    margin-block-end: 3px;
    padding: 2px;
    font-weight: bold;
    text-decoration: none;
    color: black;
    text-decoration: none;
}

.button-text {
    font-size: 12px;
    margin-block-start: 3px;
    margin-block-end: 3px;
    padding: 2px;
    font-weight: bold;
    text-decoration: none;
}

.button-text:hover {
    font-size: 12px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    text-decoration: none;
}

.header-item-link {
    font-size: 1.15em;
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    line-height: 1.5;
    color: black;
    text-decoration: none;
  }

.header-item-link a:hover{
    font-size: 1.15em;
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    line-height: 1.5;
    background-color: blue;
    color: red;
    text-decoration: none;
  }


.page-title {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 2.5em;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.page-text {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.15em;
    line-height: 1.4;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    margin-top: 12px;
}

.page-header {
    font-size: 2.9em;
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    text-align: center;
  }


.page-text-lines {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.15em;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    margin-top: 0px;
}

.page-subtitle {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.5em;
    line-height: 1.4;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    margin-top: 12px;
    font-weight: bold;
}

.program-header {
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    text-align: center;
  }
  
  .program-sub-header {
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .program-sub-header-unbold {
    margin-block-start: 18px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .program-text {
    font-size: 1em;
    margin-block-start: 7px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
  }

