/* navy: #1b213b
blue: #00448D
red: #ee4037
yellow: #fbaf40 */

body {
  margin: 0;
  font-family: Arial, sans-serif;
  padding: 0 0;
}

.page-wrapper {
  padding: 25px;
}
.home-wrapper {
  padding: 0 10%;
}

.home-wrapper h1{
  font-size: 40px;
}

.book-title {
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 30px;
}

.book-author {
  padding-bottom: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  margin-top: 10px;
  font-size: 17px;
}

.book-desc {
  font-size: 17px;
}
