.bullet {
    margin-left: 10px;
    margin-top: 7px;
    font-size: 1em;
    font-family: "IBM Plex Sans", sans-serif;
  }

.link {
    text-decoration: none;
    color: white;
  }

.link-text {
  text-decoration: none;
  color: black;
}

a:visited {
    text-decoration: none;
    color: black;
}

.program-h3 {
  margin-block-start: 18px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
}


.program-header {
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  text-align: center;
  font-size: 37px
}

.program-practice {
  font-size: 1em;
  padding-left: 10px;
  padding-top: 22px;
  padding-bottom: 22px;
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  background-color: #dee8fa;
}

.program-practice-header {
    margin-block-start: 0px;
    margin-block-end: 6px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 800;
    font-style: normal;
  }

.program-sub-header {
  font-size: 1.3em;
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.program-sub-header-unbold {
  margin-block-start: 18px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.program-text {
  font-size: 1em;
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
}

.program-text-single {
    font-size: 1em;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-family: "IBM Plex Sans", sans-serif;
}

.program-title {
    color: white;
    align-items: left;
    margin-right: 40px;
    font-size: 16px;
}