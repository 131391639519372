a:visited {
    text-decoration: none;
    color: black;
}

.app-header{
  font-size: '20px';
  text-decoration: none;
  color: #1b213b;
}

.app-header a{
  text-decoration: none;
  color: black;
}


.button-text {
  color: white;
  background-color: '#1b213b';
  text-decoration: none;
  font-size: '8px';
}

.certification {
  color: white;
  font-size: 1.15em;
  font-family: "IBM Plex Sans", sans-serif;
  background-color: #000924;
  padding: 8px;
  text-decoration: none;
  border-radius: 2.5;
}

.certification a:visited{
  color: white;
  font-size: 1.15em;
  font-family: "IBM Plex Sans", sans-serif;
  background-color: #000924;
  padding: 8px;
  text-decoration: none;
  border-radius: 2.5;
}

.header-logo {
  font-size: 1.2em;
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-weight: 900;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.5;
  color: #000924;
  text-decoration: none;
}

.header-logo:visited {
  font-size: 1.2em;
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-weight: 900;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.5;
  color: #000924;
  text-decoration: none;
}

.header-item-link {
  font-size: 1.15em;
  margin-block-start: 7px;
  margin-block-end: 6px;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.5;
  color: black;
  text-decoration: none;
}

.logo {
  color: black;  
  text-decoration: none;
  min-width: '150px';
  line-height: 1;
}

.link {
  color: black;  
  text-decoration: none;
  }

.link a{
  color: black;  
  text-decoration: none;
  }

